import { BasicsClass } from '@/common/BasicsClass';

export default class LuckyDrawTimeGiveAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getList', {
            url: '/admin/summer/giveList',
            name: 'list',
            label: '列表'
        }],
        ['give', {
            url: '/admin/summer/give',
            name: 'give',
            label: '赠送'
        }],

    ])

    public getList(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getList').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    public give(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('give').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }


}