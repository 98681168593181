
import { Component, Vue } from "vue-property-decorator";
import { Util, getPageAPIs } from "@/common/util";
import BusinessReminderListAPI from '@/common/api/operationManage/luckyDrawTimeGive';
import { DeepLoop } from "@/common/deepLoop/deepLoop";


export const pageApiList = getPageAPIs(BusinessReminderListAPI)

const maxGiveTime = 9999

const defaultForms = {
  phone: '', // 电话
  num: '', // 赠送数量
  reason: '', // 赠送原因
};

const defaultSearchVenuePageOption = {
  page: 1,
  limit: 10,
  isRearchBottom: false,
};


@Component({
  filters: {
    showMutipleShopNames(tipRow: any) {
      const {
        shop_name = '',
        assistant_shop_name = ''
      } = tipRow || {}

      const names: string[] = [shop_name, assistant_shop_name].filter((name: string) => !!name)

      return names.join('、')
    }
  }
})
export default class BusinessReminderList extends Vue {
  private form: any = {
    phone: "",
  };
  private forms: any = {
  };

  private deepLoopUtil: any = {};


  private businessReminderListApi = new BusinessReminderListAPI();

  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

  }


  private created(): void {
    this.getBusinessReminderList();

  }
  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getBusinessReminderList();
  }
  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getBusinessReminderList();
  }

  private tableData: any[] = [];

  private formLabelWidth: string = "100px";

  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getBusinessReminderList();
  }

  private addOrUpdateBusinessReminderTitle: string = "运营提醒";

  private dialogFormVisible: boolean = false;

  // 拉起运营提醒弹框
  private handleWakeBusinessReminderDialog(mode: string) {
    this.storageLockerDialogMode = mode;
    this.dialogFormVisible = true;
  }

  private storageLockerDialogMode: string = "";

  private handleLimitTime(val: string) {
    Util.limitIntegetInput(
        val,
        (new_val: string) => {
          const limit_val = Math.min(Number(new_val), maxGiveTime).toString();
          this.$set(this.forms, "num", limit_val);
        },
        (new_val: string) => {
          this.$set(this.forms, "num", new_val);
        }
    );
  }
  private sumbitBusinessReminderLoading: boolean = false;

  private handleSumbitBusinessReminder() {
    const params = this.forms;
    this.sumbitBusinessReminderLoading = true;
    this.businessReminderListApi.give(
        params,
        () => {
          this.getBusinessReminderList();
          this.dialogFormVisible = false;
          this.sumbitBusinessReminderLoading = false;
        },
        () => {
          this.sumbitBusinessReminderLoading = false;
        }
    );
  }


  //获取运营提醒列表
  private getBusinessReminderList(): void {
    this.businessReminderListApi.getList(
        { ...this.getBusinessReminderParams() },
        (res: any) => {
          this.tableData = res.data.list;
          this.tablePage.page = res.data.page;
          this.tablePage.prepage = res.data.prepage;
          this.tablePage.count = res.data.count;
        },
        () => {}
    );
  }

  private getBusinessReminderParams(mode = "all"): any {
    const params: any = {};

    const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    const { phone } = form;

    if (phone) {
      params.phone = phone;
    }



    if (mode === "all") {
      params.page = page;

      params.limit = prepage;

    }

    return params;
  }

}
